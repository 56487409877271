// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();

require("core-js/stable");
require("regenerator-runtime/runtime");

// Added by Powerpal - we rarely see iPhones running very old versions of Safari (<=10.2) which did not support fetch.
import "whatwg-fetch";

// TODO: RG, 2020-02-03: When we upgraded from Rails 5 to 6 we dumped the existing JS files from assets into webpacker.
// At some point we should go through and turn these into proper ES6 modules.
require("src/main");
require("src/util");

require("src/address_autocomplete");
require("src/energy_bridges");
require("src/table_sort");
require("src/associate_energy_bridge_with_order");
require("src/rewards");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
