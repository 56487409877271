import autocomplete from "autocomplete.js";

window.powerpal = window.powerpal || {};

window.powerpal.autocompleteAddress = (field, opts, onChanged) => {
  return addressifyAutocomplete(field, onChanged, {
    apiKey: window.powerpal.config.addressify_api_key,
    addressTypes: opts.allowPOBoxes ? 1 : 2, // 1 :Everything, 2: Valid physical addresses – https://www.addressify.com.au/documentation/#api-AddressPro-Autocomplete
    allowMissingUnitNumber: opts.allowMissingUnitNumber, // Filter out "17 Fake Street" if "1/17 Fake Street" also exists.
    maxResults: 5, // Number of selectable results to show in the list.
    minLength: 4, // Do not query API until we have at least 4 characters.
    throttleMillis: 250, // Without this an API request is made with every key stroke.
  });
};

function addressifyAutocomplete(field, onChanged, opts) {
  let addressFilter = opts.allowMissingUnitNumber ? allowAllAddresses : removeAddressesWithMissingUnitNumbers;
  let source = addressifySource(opts.apiKey, opts.maxResults, opts.addressTypes, addressFilter);

  let ac = autocomplete(
    field,
    {
      hint: false, // Not sure what hint:true actually does, other than mess up the <input> styling.
      autoselect: true, // Pre-select the first result and allow user to hit ENTER.
    },
    [
      {
        source: source,
        minLength: opts.minLength,
        debounce: opts.throttleMillis,
        displayKey: (value) => value, // This determines what appears in the input field.
        templates: {
          suggestion: (suggestion) => suggestion, // Addressify source returns a simple list of strings.
        },
      },
    ]
  );

  // Algolia add autocomplete="off", but Chrome still obscures the dropdown with a popup. Generate a unique value to fool Chrome.
  field.autocomplete = `chrome-no-autocomplete-${new Date().getTime()}`;

  let selectedValue = field.value;

  let clear = () => {
    selectedValue = null;
    field.value = null;
  };

  field.addEventListener("blur", () => {
    field.value = selectedValue;
  });

  ac.on("autocomplete:opened", () => {
    selectedValue = null;
  });

  ac.on("autocomplete:closed", async () => {
    if (selectedValue === null) return onChanged(null);

    try {
      let info = await getAddressInfo(opts.apiKey, selectedValue);
      onChanged(toPowerpalAddress(info));
    } catch (err) {
      onChanged(null);
      alert(`Error looking up address\n\n${err}\n\nPlease try again or contact Powerpal support.`);
    }
  });

  ac.on("autocomplete:selected", (event, suggestion, dataset, context) => {
    selectedValue = suggestion;
  });

  // Go up two levels to look for a clear button; the field has now been wrapped in span.algolia-autocomplete.
  let clearButton = field.parentNode.parentNode.querySelector(".clear");
  if (clearButton !== null) {
    clearButton.addEventListener("click", (event) => {
      event.preventDefault();
      clear();
      onChanged(null);
    });
  }

  // Return clear to allow consumers to do additional validation (e.g. installation booking disallowing postcode changes).
  return { clear };
}

function addressifySource(apiKey, maxResults, addressTypes, addressInfoFilter) {
  return async (query, cb) => {
    try {
      let res = await fetch(
        `https://api.addressify.com.au/addresspro/autocomplete?api_key=${apiKey}&term=${encodeURI(
          query
        )}&max_results=${maxResults}&address_types=${addressTypes}&info=true`
      );
      if (!res.ok) {
        throw new Error(`${res.status} from address lookup service`);
      }
      let addresses = await res.json();
      let allowedAddresses = addresses.filter(addressInfoFilter);

      cb(allowedAddresses.map((a) => a.AddressFull));
    } catch (err) {
      console.error(err);
      cb([]);
    }
  };
}

async function getAddressInfo(apiKey, term) {
  let res = await fetch(`https://api.addressify.com.au/addresspro/info?api_key=${apiKey}&term=${encodeURI(term)}`);
  if (!res.ok) {
    throw new Error(`${res.status} from address info service`);
  }
  return await res.json();
}

function toPowerpalAddress(a) {
  return {
    suburb: a.Suburb,
    number: a.Number,
    number_first: a.NumberFirst,
    number_last: a.NumberLast,
    street_suffix: a.StreetSuffix,
    street_suffix_full: a.StreetSuffixFull,
    state: a.State,
    street: a.Street,
    street_line: a.StreetLine,
    building_name: a.BuildingName,
    street_type: a.StreetType,
    street_type_full: a.StreetTypeFull,
    unit_type: a.UnitType,
    unit_type_full: a.UnitTypeFull,
    unit_number: a.UnitNumber,
    level_type: a.LevelType,
    level_type_full: a.LevelTypeFull,
    level_number: a.LevelNumber,
    postcode: a.Postcode,
    longitude: a.Longitude,
    latitude: a.Latitude,
    street_longitude: a.Street_Longitude,
    street_latitude: a.Street_Latitude,
    meshblock: a.Meshblock,
    meshblock_2016: a.Meshblock2016,
    gnaf_id: a.Gnaf_ID,
    is_valid: a.Valid,
  };
}

// No-op filter to allow everything returned by Addressify.
function allowAllAddresses(info, i, all) {
  return true;
}

// Remove top-level street addresses for multi-unit dwellings.
function removeAddressesWithMissingUnitNumbers(info, i, all) {
  // This is one of the unit options and should be selectable.
  if (info.UnitNumber !== null) return true;

  let anyUnitsAtStreetAddress = all.filter((a) => sameStreetAddress(a, info)).length > 1;

  return !anyUnitsAtStreetAddress;
}

// "5 St David Street" has the same street address as "5/5 St David Street".
const STREET_ADDRESS_FIELDS = ["State", "Postcode", "Suburb", "Street", "StreetType", "StreetSuffix", "Number"];

function sameStreetAddress(a, b) {
  for (let f of STREET_ADDRESS_FIELDS) {
    if (a[f] !== b[f]) return false;
  }
  return true;
}

// Helper to automatically wire up address autocompletes with options and target form fields configured via data- attributes.
window.powerpal.initAddressAutocomplete = () => {
  document.querySelectorAll("input.address-autocomplete").forEach((el) => {
    autoWireAddressAutocomplete(el);
  });
};

function autoWireAddressAutocomplete(el) {
  const targetFieldNamespace = el.dataset.targetFieldNamespace;

  // Disallow by default. Enable with data-allow-po-boxes="true".
  const allowPOBoxes = el.dataset.allowPoBoxes == "true";

  // Disallow by default. Enable with data-allow-missing-unit-number="true".
  const allowMissingUnitNumber = el.dataset.allowMissingUnitNumber == "true";

  window.powerpal.autocompleteAddress(el, { allowPOBoxes, allowMissingUnitNumber }, (address) => {
    if (address === null) {
      document.querySelectorAll(`[name^='${targetFieldNamespace}']`).forEach((el) => (el.value = null));
      return;
    }

    Object.entries(address).forEach(([k, v]) => {
      let fieldName = targetFieldNamespace ? `${targetFieldNamespace}[${k}]` : k;
      document.querySelector(`[name='${fieldName}']`).value = v;
    });
  });
}
