window.powerpal = window.powerpal || {};

document.addEventListener("DOMContentLoaded", function () {
  powerpal.initBulmaComponents();
  powerpal.initSyntaxHighlighting();
  powerpal.initAddressAutocomplete();
});

powerpal.initBulmaComponents = function () {
  const burger = document.querySelector(".navbar-burger");
  if (burger == null) return;

  burger.addEventListener("click", function () {
    burger.classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  });
};

powerpal.initSyntaxHighlighting = function () {
  if (window.hljs == undefined) return;
  hljs.initHighlightingOnLoad();
};
