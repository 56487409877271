window.powerpal = window.powerpal || {};

powerpal.populateDeviceInfo = function (meterReadingsServiceUrl, meterReadingsAuthToken, energybridgeSerialNumber) {
  var deviceInfoURL = meterReadingsServiceUrl + "/api/v1/device/" + energybridgeSerialNumber;

  fetch(deviceInfoURL, {
    method: "GET",
    headers: {
      Authorization: meterReadingsAuthToken,
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.first_reading_timestamp === 0 && data.first_archived_date === "") {
        document.querySelectorAll(".meter-readings-data .pair p").forEach((el) => (el.innerText = "N/A"));
        return;
      }

      const populateReadingDate = (containerID, timestamp, archivedDate) => {
        const container = document.getElementById(containerID);

        if (timestamp === 0) {
          container.innerText = "1 year+";
        } else {
          const m = moment.unix(timestamp);
          container.innerHTML = `<a id="first-reading-link" href="#${m.format("YYYY-MM-DD")}">
            ${m.format("YYYY-MM-DD HH:mm")}
          </a>`;
          container.addEventListener("click", () => changeDate(m.startOf("day")));
        }

        if (archivedDate !== "") {
          container.innerHTML += `<br/><span class="note">(${archivedDate} archived)</span>`;
        }
      };

      populateReadingDate("first-reading-date", data.first_reading_timestamp, data.first_archived_date);
      populateReadingDate("last-reading-date", data.last_reading_timestamp, data.last_archived_date);

      document.getElementById("total-readings-count").innerText = data.total_meter_reading_count;
      document.getElementById("total-kwh-measured").innerText = `${(data.total_watt_hours / 1000).toFixed(2)}KWh`;
      document.getElementById("total-cost-measured").innerText = `$${data.total_cost.toFixed(2)}`;
    })
    .catch(console.error);
};

powerpal.initCharts = function (meterReadingsServiceUrl, meterReadingsAuthToken, energybridgeSerialNumber) {
  Chart.defaults.global.defaultFontColor = "#4a4a4a";

  powerpal.meterReadingsServiceUrl = meterReadingsServiceUrl;
  powerpal.energybridgeSerialNumber = energybridgeSerialNumber;
  powerpal.meterReadingsAuthToken = meterReadingsAuthToken;

  powerpal.charts = [
    {
      id: 0,
      context: document.getElementById("chart0").getContext("2d"),
      instance: null,
      type: "line",
      readings: [],
      start: null,
      end: null,
      sampleRate: 1,
      showExtraData: true,
    },
    {
      id: 1,
      context: document.getElementById("chart1").getContext("2d"),
      instance: null,
      type: "line",
      readings: [],
      start: null,
      end: null,
      sampleRate: 10,
    },
    {
      id: 2,
      context: document.getElementById("chart2").getContext("2d"),
      instance: null,
      type: "bar",
      readings: [],
      start: null,
      end: null,
      sampleRate: 60,
    },
  ];

  let urlDate = document.location.hash.match(/#([\d-]+)$/);
  if (urlDate == null) {
    changeDate(moment().startOf("day")); // Default to current day.
  } else {
    changeDate(moment(urlDate[1]));
  }

  var datePicker = document.getElementById("date-picker");
  powerpal.pikaday = new Pikaday({
    field: datePicker,
    theme: "powerpal-pikaday-theme",
    firstDay: 1,
  });
  // Bind change event after creating date picker to avoid unnecessary initial triggering.
  datePicker.addEventListener("change", () => {
    changeDate(moment(datePicker.value));
  });

  document.getElementById("date-select-today").addEventListener("click", (ev) => {
    changeDate(moment().startOf("day"));
    ev.preventDefault();
  });

  document.getElementById("date-select-prev").addEventListener("click", (ev) => {
    prevDate();
    ev.preventDefault();
  });

  document.getElementById("date-select-next").addEventListener("click", (ev) => {
    nextDate();
    ev.preventDefault();
  });

  window.autorefreshTimer = setInterval(powerpal.refreshAllCharts, 30e3);
};

function render(chart) {
  if (chart.instance != null) {
    chart.instance.destroy();
  }

  renderChart(chart);

  var totalWattHours = chart.readings.reduce((total, r) => total + r.watt_hours, 0);
  document.querySelector("#chart" + chart.id + "-header .total-kilowatt-hours").innerText =
    round(totalWattHours / 1000, 2) + " kWh";

  var totalCost = chart.readings.reduce((total, r) => total + r.cost, 0);
  document.querySelector("#chart" + chart.id + "-header .total-cost").innerText = "$" + round(totalCost, 2);
}

function renderChart(chart) {
  if (chart.type == "line") {
    renderLineChart(chart);
  } else {
    renderBarChart(chart);
  }
}

function renderLineChart(chart) {
  var datasets = [];
  var yAxes = [];

  var maxWatts = parseInt(document.getElementById("maxwatts").value);

  if (document.getElementById("toggle-watts").checked) {
    datasets.push({
      data: chart.readings.map((r) => ({ t: moment.unix(r.timestamp), y: (r.watt_hours * 60) / chart.sampleRate })),
      label: "Watts",
      yAxisID: "watts",
      backgroundColor: "rgba(3, 152, 220, 0.3)",
      borderColor: "rgba(3, 152, 220, 1)",
      borderWidth: 1,
      radius: 0,
      spanGaps: false,
    });
    yAxes.push({
      id: "watts",
      ticks: {
        beginAtZero: true,
        callback: function (value, index, values) {
          return round(value, 1) + "W";
        },
        min: 0,
        max: maxWatts,
      },
      gridLines: {
        color: "rgba(0, 0, 0, 0.1)",
      },
    });
  }

  if (chart.showExtraData && document.getElementById("toggle-cost").checked) {
    datasets.push({
      data: chart.readings.map((r) => ({ t: moment.unix(r.timestamp), y: (r.cost * 60) / chart.sampleRate })),
      label: "Cost",
      yAxisID: "cost",
      backgroundColor: "rgba(128, 187, 137, 0.3)",
      borderColor: "rgba(128, 187, 137, 1)",
      borderWidth: 1,
      radius: 0,
      spanGaps: false,
    });
    yAxes.push({
      id: "cost",
      position: "right",
      ticks: {
        beginAtZero: true,
        callback: function (value, index, values) {
          return "$" + round(value, 2) + "/h";
        },
      },
      gridLines: {
        display: false,
      },
    });
  }

  chart.instance = new Chart(chart.context, {
    type: "line",
    data: {
      datasets: datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      animation: false,
      legend: {
        display: true,
      },
      scales: {
        xAxes: [
          {
            type: "time",
            distribution: "linear",
            time: {
              min: chart.start,
              max: chart.end,
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
        yAxes: yAxes,
      },
      tooltips: {
        callbacks: {
          title: (items, data) => moment(items[0].xLabel).toString(),
        },
      },
    },
  });
}

function renderBarChart(chart) {
  chart.instance = new Chart(chart.context, {
    type: "bar",
    data: {
      datasets: [
        {
          label: "kWh",
          data: chart.readings.map((r) => ({ t: moment.unix(r.timestamp).startOf("hour"), y: r.watt_hours / 1e3 })),
          backgroundColor: "rgba(3, 152, 220, 0.3)",
          borderColor: "rgba(3, 152, 220, 1)",
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      animation: false,
      legend: {
        display: true,
      },
      scales: {
        xAxes: [
          {
            type: "time",
            distribution: "linear",
            time: {
              min: chart.start,
              max: chart.end,
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return round(value, 1) + "kWh";
              },
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
      },
    },
  });
}

function round(n, dp) {
  return Math.round(n * Math.pow(10, dp)) / Math.pow(10, dp);
}

function changeDate(m) {
  powerpal.charts[0].start = m;
  powerpal.charts[0].end = moment(m).endOf("day");

  powerpal.charts[1].start = moment().subtract({ days: 7 });
  powerpal.charts[1].end = moment();

  powerpal.charts[2].start = powerpal.charts[0].start;
  powerpal.charts[2].end = powerpal.charts[0].end;

  document.getElementById("date-picker").value = m.format("YYYY-MM-DD");

  if (m.isSame(moment(), "date")) {
    document.getElementById("chart0-date").innerText = "Today";
    document.getElementById("chart2-date").innerText = "Today";

    history.replaceState({}, "", document.location.href.split("#")[0]); // Clear date from URL so permalinks will always show current date.
  } else {
    document.getElementById("chart0-date").innerText = m.format("dddd Do MMM");
    document.getElementById("chart2-date").innerText = m.format("dddd Do MMM");
    history.replaceState({}, "", document.location.href.split("#")[0] + "#" + m.format("YYYY-MM-DD"));
  }

  powerpal.refreshAllCharts();
}

function nextDate() {
  changeDate(moment(powerpal.charts[0].start).add({ days: 1 }));
}

function prevDate() {
  changeDate(moment(powerpal.charts[0].start).subtract({ days: 1 }));
}

function downloadAndRender(chart) {
  var readingsURL =
    powerpal.meterReadingsServiceUrl +
    "/api/v1/meter_reading/" +
    powerpal.energybridgeSerialNumber +
    "?start=" +
    chart.start.unix() +
    "&end=" +
    chart.end.unix() +
    "&sample=" +
    chart.sampleRate;

  fetch(readingsURL, {
    method: "GET",
    headers: {
      Authorization: powerpal.meterReadingsAuthToken,
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      chart.readings = data;
      render(chart);
    })
    .catch(console.error);
}

// Exposed to be called from the cost toggle, scale changer etc. on the page.
powerpal.refreshAllCharts = function () {
  downloadAndRender(powerpal.charts[0]);
  downloadAndRender(powerpal.charts[1]);
  downloadAndRender(powerpal.charts[2]);
};
