window.powerpal = window.powerpal || {};

window.powerpal.associateEnergyBridgeWithOrder = (form) => {
  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();
    let serialNumber = form.serial_number.value;

    try {
      let res = await fetch(form.action, {
        method: form.method,
        body: new FormData(form),
      });

      if (!res.ok) {
        let data = await res.json();
        throw data.error;
      }

      // Focus on next form if we're doing a bulk entry from the /orders index page.
      let nextForm = nextOnPage(form, "form.associate-energy-bridge");
      if (nextForm !== undefined) {
        nextForm.querySelector("[name=serial_number]").focus();
      }

      let el = document.createElement("a");
      el.href = `/energy_bridges/${serialNumber}`;
      el.innerText = serialNumber;
      form.parentNode.replaceChild(el, form);
    } catch (err) {
      alert(`Error associating ${serialNumber} with order:\n\n${err}`);
    }
  });
};

// Can't find a neat way to find the next occurance in the DOM without iterating.
function nextOnPage(el, selector) {
  let elements = Array.from(document.querySelectorAll(selector));
  for (let i = 0; i < elements.length; i++) {
    if (elements[i] === el) {
      return elements[i + 1];
    }
  }
}
