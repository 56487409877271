window.powerpal = window.powerpal || {};

powerpal.util = {
  disableLink: (selector) => {
    let link = document.querySelector(selector);
    link.dataset.originalHref = link.href;
    link.removeAttribute("href");
    link.setAttribute("disabled", true);
  },
  enableLink: (selector) => {
    let link = document.querySelector(selector);
    link.removeAttribute("disabled");
    link.setAttribute("href", link.dataset.originalHref);
  },
  show: (...els) => {
    els.forEach((el) => {
      if (el == null) return;
      el.classList.remove("is-hidden");
    });
  },
  hide: (...els) => {
    els.forEach((el) => {
      if (el == null) return;
      el.classList.add("is-hidden");
    });
  },
  showAllHiddenUntilReady: () => {
    for (let el of document.querySelectorAll(".is-hidden-until-ready")) {
      el.classList.remove("is-hidden-until-ready");
    }
  },
  createElementFromHTML: (html) => {
    var div = document.createElement("div");
    div.innerHTML = html.trim();
    return div.firstChild;
  },
};
