window.powerpal = window.powerpal || {};

window.powerpal.rewards = {
  initFulfillmentPage: (rewardsServiceUrl, installationsServiceUrl, apiToken) => {
    showGiftCardStocks();
    wireUpGiftCardImport();
    showUnfulfilledRewards();

    async function showGiftCardStocks() {
      try {
        const el = document.getElementById("gift-card-stocks");

        const res = await fetch(`${rewardsServiceUrl}/api/v1/gift_cards/count`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        });
        if (!res.ok) throw new Error(res.statusText);
        const data = await res.json();

        const sum = (counts) => counts.reduce((sum, c) => sum + c.denomination * c.count, 0);
        el.querySelector("#total-used").innerText = `$${sum(data.used)}`;
        el.querySelector("#total-available").innerText = `$${sum(data.available)}`;

        el.querySelector("#available-counts").innerHTML = data.available
          .map(
            (c) => `
          <div class="pair">
            <h6>$${c.denomination}</h6>
            <p><em>${c.count}</em></p>
          </div>
        `
          )
          .join("");
      } catch (err) {
        onError(err);
      }
    }

    function wireUpGiftCardImport() {
      const el = document.getElementById("gift-card-import");
      const field = el.querySelector("input[name=csv]");
      field.addEventListener("change", upload);

      async function upload() {
        try {
          const res = await fetch(`${rewardsServiceUrl}/api/v1/gift_cards`, {
            method: "POST",
            headers: {
              "Content-Type": "text/csv",
              Authorization: `Bearer ${apiToken}`,
            },
            body: field.files[0],
          });
          if (!res.ok) throw new Error(res.statusText);
        } catch (err) {
          onError(err);
        } finally {
          document.location.reload();
        }
      }
    }

    async function showUnfulfilledRewards() {
      try {
        const el = document.getElementById("unfulfilled-rewards");

        const res = await fetch(`${rewardsServiceUrl}/api/v1/rewards/fulfillment`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        });
        if (!res.ok) throw new Error(res.statusText);
        const data = await res.json();

        if (data.rewards.length === 0) {
          el.innerText = "There are no outstanding rewards to be fulfilled.";
          return;
        }

        showRequiredGiftCards(el, data);
        showRewardsTable(el, data);
        wireUpFulfillment(el, data);
      } catch (err) {
        onError(err);
      }
    }

    function showRequiredGiftCards(el, data) {
      const sum = (rewards) => rewards.reduce((sum, r) => sum + r.value, 0);
      el.querySelector("#total-unfulfilled").innerText = `$${sum(data.rewards)}`;

      el.querySelector("#required-counts").innerHTML = data.gift_cards
        .map(
          (c) => `
      <div class="pair">
        <h6>$${c.denomination}</h6>
        <p class="${c.required <= c.available ? "has-text-success" : "has-text-danger"}">
          <em>${c.required}</em> (${c.available} available)
        </p>
      </div>
    `
        )
        .join("");
    }

    function showRewardsTable(el, data) {
      el.querySelector("#unfulfilled-rewards").innerHTML = `
        <table class="table is-striped is-hoverable">
          <thead>
          <tr>
            <th>Recipient</th>
            <th>Total owed</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          ${data.rewards.map((r) => toRow(r)).join("")}
        </tbody>
      </table>`;

      function toRow(r) {
        return `<tr>
          <td><a href="/energy_bridges/${r.serial_number}">${r.serial_number}</a></td>
          <td>$${r.value}</td>
          <td>${listReasons(r.rewards)}</td>
        </tr>`;
      }

      function listReasons(reasons) {
        function reason(r) {
          switch (r.reason_key) {
            case "installation-referral":
              return `<a href="${installationsServiceUrl}/installations/${r.reason_id}">
                ${r.reason_subject}'s installation
              </a>`;
            case "demand-response-program":
              return `The ${r.reason_subject} program`;
            default:
              return "An unknown reason";
          }
        }

        return reasons.map((r) => `$${r.value} for ${reason(r)}`).join("<br/>");
      }
    }

    function wireUpFulfillment(el, data) {
      if (data.fulfillable !== true) {
        el.querySelector("#fulfillment").innerHTML = `
          <p class="has-text-danger">You must add more gift cards before these rewards can be fulfilled.</p>
        `;
        return;
      }

      const btn = powerpal.util.createElementFromHTML(`
        <button class="button is-primary"><span class="icon"><i class="fas fa-medal fa-1x"></i></span><span>Fulfill all rewards</span></a>
      `);
      el.querySelector("#fulfillment").appendChild(btn);
      btn.addEventListener("click", fulfill);

      async function fulfill() {
        try {
          btn.classList.add("is-loading");
          btn.disabled = true;

          const res = await fetch(`${rewardsServiceUrl}/api/v1/rewards/fulfillment`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${apiToken}`,
            },
          });
          if (!res.ok) throw new Error(res.statusText);
        } catch (err) {
          onError(err);
        } finally {
          document.location.reload();
        }
      }
    }
  },
};

function onError(err) {
  console.error(err);
  alert(err);
}
